import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { Navbar, Nav, Container } from "react-bootstrap"

import Logo from "../Logo"
import {
  NavLinkContainer,
  Brand,
  NavLink,
  NavlinkWhite,
  NavLinkActive,
  NavBar,
} from "./style.module.scss"

const Header = ({ siteTitle, logoColor = "white" }) => (
  <Navbar
    variant={logoColor === "white" ? "dark" : "light"}
    className={NavBar}
    expand="md"
  >
    <Container>
      <Link to="/" className={`${Brand} navbar-brand`}>
        <Logo color={logoColor} />
      </Link>
      <Navbar.Toggle aria-controls="navbar-collapse" />
      <Navbar.Collapse id="navbar-collapse" className="justify-content-end">
        <Nav className={NavLinkContainer}>
          <Link
            to="/"
            className={`${NavLink} ${logoColor === "white" && NavlinkWhite}`}
            activeClassName={NavLinkActive}
          >
            Home
          </Link>
          <Link
            to="/about"
            className={`${NavLink} ${logoColor === "white" && NavlinkWhite}`}
            activeClassName={NavLinkActive}
            partiallyActive={true}
          >
            About
          </Link>
          <Link
            to="/services"
            className={`${NavLink} ${logoColor === "white" && NavlinkWhite}`}
            activeClassName={NavLinkActive}
            partiallyActive={true}
          >
            Services
          </Link>
          {/* <Link
            to="/blog"
            className={`${NavLink} ${logoColor === "white" && NavlinkWhite}`}
            activeClassName={NavLinkActive}
          >
            Blog
          </Link> */}
          <Link
            to="/contact"
            className={`${NavLink} ${logoColor === "white" && NavlinkWhite}`}
            activeClassName={NavLinkActive}
            partiallyActive={true}
          >
            Contact
          </Link>
        </Nav>
      </Navbar.Collapse>
    </Container>
  </Navbar>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
