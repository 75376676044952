import React from "react"

import LogoWhite from "./Logo-White.svg"
import LogoColor from "./Logo-Color.svg"

export default ({ color = "white" }) => {
  return (
    <img
      src={color == "white" ? LogoWhite : LogoColor}
      alt={"Freshnode logo"}
      width={240}
    />
  )
}
